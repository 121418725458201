export function numberFormatter(number, delimiter = " ") {
  if (number)
    return number?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, delimiter)
  else return number
}

export function numberFormatterV2(x, productCardRelated) {
  x = x?.toString?.()
  if (x?.includes?.(",") && x?.includes?.(".")) {
    x = x?.replace?.(/\./g, "")
    x = x?.replace?.(/\,/g, ".")
  }

  if (productCardRelated) {
    x = x?.replace?.(/.(?=\d{3})/g, "")
  }

  x = (Math.round(x * 100) / 100)?.toFixed(2)
  return x
    ?.replace?.(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    ?.replace?.(/\,/g, ".")
    ?.replace?.(/\.([^\.]*)$/, "," + "$1")
}

export function convertFile2Base64(file) {
  if (!file) return Promise.reject()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => reject(error)
  })
}

export function jsonCheck(input) {
  return typeof input === "string" ? JSON.parse(input) : input
}

export const searchItems = (searchString, dataViewItems) => {
  if (!searchString) return dataViewItems
  if (searchString === '') return dataViewItems
  return dataViewItems?.filter?.((obj) =>
    trimmAndLowerString(JSON.stringify(obj))?.includes?.(searchString)
  )
}
const trimmAndLowerString = (rawString) =>
  rawString ? rawString?.replace?.(/\s+/g, "")?.toLowerCase?.() : ""

export const searchExcelItems = (searchString, dataViewItems) => {
    if (!searchString) return dataViewItems
    if (searchString === '') return dataViewItems
    return dataViewItems?.filter?.((obj) =>
      trimmAndLowerString(`${obj?.name} ${obj?.brandName} ${obj?.code}`)?.includes?.(trimmAndLowerString(searchString))
    )
  }
export const searchListItems = (searchString, dataViewItems) => {
    if (!searchString) return dataViewItems
    if (searchString === '') return dataViewItems
    return dataViewItems?.filter?.((obj) =>
      trimmAndLowerString(`${obj?.title} ${obj?.brandName} ${obj?.productCode}`)?.includes?.(trimmAndLowerString(searchString))
    )
  }
export const filterDynamoDataViewItemsByName = (searchString, dataViewItems) => {
    if (!searchString) return dataViewItems
    if (searchString === '') return dataViewItems
    return dataViewItems?.filter?.((obj) =>
      trimmAndLowerString(`${obj?.values?.name} `)?.includes?.(trimmAndLowerString(searchString))
    )
  }
export const filterDynamoDataViewItemsByJobName = (searchString, dataViewItems) => {
    if (!searchString) return dataViewItems
    if (searchString === '') return dataViewItems
    return dataViewItems?.filter?.((obj) =>
      trimmAndLowerString(`${obj?.values?.content?.[0]?.title} `)?.includes?.(trimmAndLowerString(searchString))
    )
}
export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return string
  return (
    string?.charAt?.(0)?.toUpperCase?.() + string?.slice?.(1)?.toLowerCase?.()
  )
}

export const getInitialsFromName = (title) =>
  String(title)
    ?.split?.(" ")
    ?.slice?.(0, 2)
    ?.map?.((i) => i?.[0]?.toUpperCase?.())
    ?.join?.("")

export const getInitialsFromCompanyName = (title) =>
  String(title)
    ?.split?.(" ")
    ?.slice?.(0, 3)
    ?.map?.((i) =>
      !["srl", "srl.", "sa", "sa.", "srl-d"]?.includes?.(
        i?.toLocaleLowerCase?.()
      )
        ? i?.[0]?.toUpperCase?.()
        : ""
    )

export function convertCategoryTreeToList(categories) {
  let stack = [],
    result = []
  if (!categories || categories?.length < 1) return result

  stack?.push?.(categories)
  while (stack?.length > 0) {
    
    let node = stack?.pop?.()

    if(!node) continue

    result?.push?.(node)
    
    if (node?.parentCategory !== null) {
      stack?.push?.(node?.parentCategory)
    }
  }
  return result?.reverse?.()
}


export const toTitleCase = str => str?.replace?.(
  /\w\S*/g,
  txt =>  {
    const firstLetter = txt?.charAt?.(0)?.toUpperCase?.() || ''
    const restOfTheString = txt?.substr(1)?.toLowerCase?.() || ''

    return `${firstLetter}${restOfTheString}`
  }
) || ''



export const getRandomId = () => 
  (Math.random() + 1)?.toString?.(36)?.substring?.(2)?.toUpperCase?.()

  
export const isLowercasedEqual = (str1, str2) => {
  if (str1 === str2) return true
  return str1?.toLowerCase?.() === str2?.toLowerCase?.()
}

import download from "mockData/downloadCenter"
import searchDATA from "mockData/searchList"
import { apiAction, reDispatch } from "store/middleware/api"
import { setApiResponse } from "store/modules/auth"
import { searchStuff } from "store/modules/dataView.search"
import {
  // orderAutoModalData,
  orderManualModalData
} from "staticData/systemMessages"
import {
  clearLoadingIndicator,
  setCrtFormItem,
  setLoadingIndicator,
  setPageModal
} from "store/modules/page"
import moment from "moment"
import { showMagicToaster } from "utils/MagicToasterCall"
import { setDictionary } from "store/modules/backData"
import { SET_DICTIONARY } from "store/modules/backData"
import { loadItem } from "store/justActions/loadApiIems"
import store from "store"
import { translatedToastr } from "utils/translatedToastr"
import { toastr } from "react-redux-toastr"
import { printError } from "utils/printError"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"
import { purchaseResponseToGoogleEvent } from "utils/googleAnalyticsUtils"
import TagManager from "react-gtm-module"

const ADD_TO_CART = "shop/ADD_TO_CART"
const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING"
const SET_CART_SEARCH_STRING = "shop/SET_CART_SEARCH_STRING"
export const SET_SEARCH_RESULTS = "shop/SET_SEARCH_RESULTS"
const SET_MENU_SEARCH_STRING = "shop/SET_MENU_SEARCH_STRING"
const TOGGLE_VIEW_TYPE = "shop/TOGGLE_VIEW_TYPE"
const SET_SHOW_PRICES = "shop/SET_SHOW_PRICES"
const SET_LISTS = "shop/SET_LISTS"

const SET_EXPIRED_BASKET = "shop/SET_EXPIRED_BASKET"
const SET_CART_DATA = "shop/SET_CART_DATA"
const SET_OLD_CART_DATA = "shop/SET_OLD_CART_DATA"
const CLEAR_CART_DATA = "shop/CLEAR_CART_DATA"
const SET_BOTH_CART_DATA = "shop/SET_BOTH_CART_DATA"
const SET_BACKGROUND_CART_DATA = "shop/SET_BACKGROUND_CART_DATA"
const SET_BOTH_CART_DATA_KEEP_EXISTING_PHOTOS = "shop/SET_BOTH_CART_DATA_KEEP_EXISTING_PHOTOS"
const SET_BOTH_CART_DATA_UPDATE_JUST_QUANTITY = "shop/SET_BOTH_CART_DATA_UPDATE_JUST_QUANTITY"

const SET_IS_ACCEPT_CAMPAIGN_CHECKED = "shop/SET_IS_ACCEPT_CAMPAIGN_CHECKED"


const SET_CAMPAIGN_API_INFO = "page/SET_CAMPAIGN_API_INFO"

const mock_Product = {
  badgeTitle: "Badge Title",
  brandName: "Brand name",
  price: 130.33,
  fullPrice: "98.285,12 RON (cu TVA)",
  initialPrice: "98.285,12 RON",
  youSave: "-20%",
  title:
    "This is the product title with a hard limit of 70 (seventy) characters",
  stock: 2,
  stockMessage: "Raspundem in 2 ore",
  rating: 4.3,
  reviews: 120,
  clientType: "guest",
  priceRange: "288,21 - 323,32",
  quantity: 0,
  quantityUnit: "m2",
  totalPrice: "1.878.355,22 RON",

  deals: [
    {
      id: "0001",
      quantity: 10,
      price: "8.355,22 RON",
      discount: "-2%",
      recomanded: false
    },
    {
      id: "0002",
      quantity: 20,
      price: "78.355,22 RON",
      discount: "-3%",
      recomanded: true
    },
    {
      id: "0003",
      quantity: 30,
      price: "878.355,22 RON",
      discount: "-5%",
      recomanded: false
    },
    {
      id: "0004",
      quantity: 40,
      price: "1.878.355,22 RON",
      discount: "-10%",
      recomanded: false
    }
  ]
}


const initialCampaignApiInfo = {
  /** Nr of company's campaign-orders this week (since their first 
   * order that got them into the campaign). */
  thisWeekOrderCount: 0,

  /** Is just a boolean check if thisWeekOrderCount > 0. */
  isCompanyInCampaign: null,

  /** This field has a misleading name. It actually becomes false if  
   * the user REFUSED the campaign in the past. Meant to be used to 
   * decide the initial state of the campaign-related checkbox. */
  isUserInCampaign: true,
  
}


const initialState = {
  crtProductDetails: download,
  cart: [],
  cartData: {},
  oldCartData: {},
  menuSearchItems: { ...searchDATA, dbList: [] },
  menuSeachString: "",
  cartSearchString: "",
  products: [
    { ...mock_Product, id: "PRD-aaaa0001", badgeTitle: null },
    {
      ...mock_Product,
      brandName: "Portelan inc.",
      stock: 100,
      title: "Gresie portelanata Gri",
      initialPrice: null,
      youSave: null,
      badgeTitle: null,
      id: "PRD-aaaa0002"
    },
    { ...mock_Product, id: "PRD-aaaa0003" },
    {
      ...mock_Product,
      id: "PRD-aaaa0004",
      quantityUnit: "L",
      stock: 0,
      youSave: null,
      badgeTitle: null
    },
    { ...mock_Product, id: "PRD-aaaa0005", stock: 100, badgeTitle: null },
    { ...mock_Product, id: "PRD-aaaa0006", stock: 1, youSave: null }
  ],
  searchString: "",
  viewType: "cards",
  showPrices: ["wVat", "woVat"],
  lists: [], 

  
  isAcceptCampaignChecked: false,

  /** Holds the campaign-related fields as 
   * they were returned by these API endpoints: 
   * /my-basket, /finalization, /modify-basket. */
  campaignApiInfo: { ...initialCampaignApiInfo },
  isRefreshApiAlreadyCalled: false
}

const basketValidity = 10 //minutes
var expDate = moment().add(basketValidity, "m").toDate().toISOString()

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    
    case SET_CAMPAIGN_API_INFO:
      return {
        ...state,
        campaignApiInfo: {
          ...initialCampaignApiInfo,
          ...action?.payload
        }
      }

    case SET_IS_ACCEPT_CAMPAIGN_CHECKED: return { 
      ...state, isAcceptCampaignChecked: action?.payload
    }

    case SET_CART_DATA:
      return {
        ...state,
        cartData: {
          ...action.payload,
          expDate,
          eDate: new Date().toISOString(),
          expired: false
        },
        isRefreshApiAlreadyCalled: false
      }
    case SET_BOTH_CART_DATA:
      return {
        ...state,
        cartData: {
          ...action.payload?.cartData,
          expDate,
          eDate: new Date().toISOString(),
          expired: false
        },
        oldCartData: {
          ...action.payload?.oldCartData,
          eDate: new Date().toISOString()
        },
        isRefreshApiAlreadyCalled: true
      }
    case SET_BACKGROUND_CART_DATA:
      return {
        ...state,
        cartData: {
          ...state?.cartData,
          productCount: action?.payload?.productCount,
          lineList: updateLineList(state?.cartData?.lineList, action?.payload)
        },
      }
    case  SET_BOTH_CART_DATA_UPDATE_JUST_QUANTITY: {
        //in this case we do not need to update old cart data
        //because we lose the error codes - BE malfunction
        // so we just clear the old items error data if it was changed
        const actionCartData = {
          ...action.payload?.cartData,
          expDate,
          eDate: new Date().toISOString(),
          expired: false
        }
        const actionCartDataOldPhotos = {
          ...actionCartData,
          lineList: actionCartData?.lineList?.map?.((i, index) => 
            ({...i, productMainImage: state?.cartData?.lineList?.find?.(j => j?.productId === i?.productId)?.productMainImage})
          )
        }
        const updatedOldOldCart = {
          ...state?.oldCartData,
          lineList: state?.oldCartData?.lineList?.map?.(i => {
            if(i?.productId === action?.payload?.id) return {...i, errorCode: null}
            return i
          })
        }
        return {
          ...state,
          cartData: actionCartDataOldPhotos,
          oldCartData: {
            ...updatedOldOldCart,
            eDate: new Date().toISOString()
          }
        }
    }
    case SET_BOTH_CART_DATA_KEEP_EXISTING_PHOTOS: {
        //api returns null for images,
        //we need to keep old products url
      const actionCartData = {
        ...action.payload?.cartData,
        expDate,
        eDate: new Date().toISOString(),
        expired: false
      }
      const actionCartDataOldPhotos = {
        ...actionCartData,
        lineList: actionCartData?.lineList?.map?.((i, index) => 
          ({...i, productMainImage: state?.cartData?.lineList?.find?.(j => j?.productId === i?.productId)?.productMainImage})
        )
      }
      const oldUpdatedOldCartData = state?.isRefreshApiAlreadyCalled
        ?
          state?.oldCartData
        : 
          action.payload?.oldCartData
      return {
        ...state,
        cartData: actionCartDataOldPhotos,
        oldCartData: {
          ...oldUpdatedOldCartData,
          eDate: new Date().toISOString()
        },
        isRefreshApiAlreadyCalled: true
      }
    }
    case SET_OLD_CART_DATA:
      return {
        ...state,
        oldCartData: {
          ...action.payload,
          eDate: new Date().toISOString()
        }
      }
    case CLEAR_CART_DATA:
      return {
        ...state,
        cartData: {},
        oldCartData: {}
      }
    case SET_EXPIRED_BASKET:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          expired: action.payload
        }
      }
    case ADD_TO_CART:
      let [cart, products] = addToCartArray(
        state.cart,
        state.products,
        action.payload
      )
      return {
        ...state,
        cart,
        products
      }
    case SET_LISTS:
      return {
        ...state,
        lists: action.payload
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload
      }
    case SET_CART_SEARCH_STRING:
      return {
        ...state,
        cartSearchString: action.payload
      }
    case SET_SHOW_PRICES:
      return {
        ...state,
        showPrices: action.payload
      }
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        menuSearchItems: action.payload?.items
      }
    case SET_MENU_SEARCH_STRING:
      return {
        ...state,
        menuSeachString: action.payload
      }
    case TOGGLE_VIEW_TYPE:
      return {
        ...state,
        viewType: state.viewType === "cards" ? "list" : "cards"
      }
    default:
      return state
  }
}

export const setCampaignApiInfo = campaignApiInfo => ({
  type: SET_CAMPAIGN_API_INFO,
  payload: campaignApiInfo
})


export const setIsAcceptCampaignChecked = isAcceptCampaignChecked => ({
  type: SET_IS_ACCEPT_CAMPAIGN_CHECKED, payload: isAcceptCampaignChecked
})




export const setExpiredBasket = (payload) => {
  return {
    payload,
    type: SET_EXPIRED_BASKET
  }
}
export const setLists = (payload) => {
  return {
    payload,
    type: SET_LISTS
  }
}

export const addToCart = (product, quantity) => {
  return {
    payload: {
      ...product,
      quantity
    },
    type: ADD_TO_CART
  }
}
/* export const emptyBasket = () => {
    reDispatch (dispatch => {
      const state = store.getState()
      const lineList = state.shop?.cartData?.lineList
      lineList?.foreach(el => dispatch(modifyBasket(el.productId, 0)));
      })
      return { type: 'a' }
} */

export const toggleViewType = () => {
  return {
    type: TOGGLE_VIEW_TYPE
  }
}
export const setShowPrices = (data) => {
  return {
    type: SET_SHOW_PRICES,
    payload: data
  }
}

export const setProductsSearchString = (string) => {
  return {
    payload: string,
    type: SET_SEARCH_STRING
  }
}

export const setCartSearchString = (string) => {
  return {
    payload: string,
    type: SET_CART_SEARCH_STRING
  }
}

function addToCartArray(cart, listedProducts, product) {
  let isInTheCart = false
  let newCart = [...cart || []]
  let newListedProducts = [...listedProducts || []]
  newCart?.forEach?.((cartObject, index) => {
    if (cartObject.id === product.id) {
      cartObject.quantity = product.quantity
      isInTheCart = index
    }
  })
  if (isInTheCart === false) newCart?.push?.(product)
  if (product?.quantity === 0 && isInTheCart !== false)
    newCart?.splice?.(isInTheCart, 1)

  //after each add we will always refresh the lsitedProducts array
  newListedProducts?.forEach?.((listPrObject) => {
    if (listPrObject.id === product.id) listPrObject.quantity = product.quantity
  })
  return [newCart, newListedProducts]
}

//we usually just name the variables what they are, WH type
// you can use find to find stuff in arrays
// update the product list by using the new item that caused the modificaition. minus one for loop
// reafactor with arrrow functions

export const setMenuSearchItems = (items) => {
  //make api call with string
  //get result from API
  //searchDATA = API result
  return {
    payload: { items },
    type: SET_SEARCH_RESULTS
  }
}
export const clearMenuSearchItems = () => {
  //make api call with string
  //get result from API
  //searchDATA = API result
  return {
    payload: { items: [] },
    type: SET_SEARCH_RESULTS
  }
}

// export const setMenuSearchString = (searchString) => ({
//       payload: searchString,
//       type: SET_MENU_SEARCH_STRING,
//   })

export const setMenuSearchString = (searchString) =>
  reDispatch((dispatch) => {
    //make api call with searchString
    //get result from API
    //searchDATA = API result
    
    // if (searchString?.length > 2) {
    //   //call api to get results
    //   // dispatch(setMenuSearchItems())
    //   dispatch(
    //     searchStuff({
    //       searchTerm: searchString,
    //       type: "global",
    //       target: "menuSearch",
    //       reload: true,
    //       pageSize: 10
    //     })
    //   )
    // } else dispatch(clearMenuSearchItems())

    return {
      payload: searchString,
      type: SET_MENU_SEARCH_STRING
    }
  })

export const setCartData = (cartData) => ({
  payload: cartData,
  type: SET_CART_DATA
})

const computeType = (isRefresh, id) => {
  if (isRefresh) return SET_BOTH_CART_DATA_KEEP_EXISTING_PHOTOS
  if(id) return SET_BOTH_CART_DATA_UPDATE_JUST_QUANTITY
  return SET_BOTH_CART_DATA
} 
export const setBothCartData = (cartData, oldCartData, isRefresh, id) => ({
  payload: {
    cartData,
    oldCartData,
    id
  },
  type: computeType(isRefresh, id)
})
export const setBackgroundCartData = (data) => ({
  payload: data,
  type: SET_BACKGROUND_CART_DATA
})

export const setOldCartData = (cartData) => ({
  payload: cartData,
  type: SET_OLD_CART_DATA
})

export const saveProduct = (data) =>
  apiAction({
    endPoint: `/product`,
    method: "POST",
    data,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      return {
        type: "a"
      }
    },
    onFailure: (error) => {
      printError("saveProduct()", error)
      return {
        type: "a"
      }
    }
  })

export const modifyBasket = (id, quantity, price, sourceId) =>
  reDispatch((dispatch) => {
    dispatch(setLoadingIndicator(sourceId, true))
    return apiAction({
      endPoint: `/basket/modify-basket-item`,
      method: "POST",
      data: {
        goodsId: id,
        quantity: quantity,
        price: price?.toFixed(4)
      },
      onSuccess: (data, dispatch) => {

        dispatch(setCampaignApiInfo({
          thisWeekOrderCount: data?.basket?.thisWeekOrderCount,
          isCompanyInCampaign: data?.basket?.isCompanyInCampaign,
          isUserInCampaign: data?.basket?.isUserInCampaign,
        }))

        dispatch(clearLoadingIndicator(sourceId))
        // dispatch(getCart())
        return setBothCartData(data?.basket, data?.lastBasket, false, id)
      },
      onFailure: (err) => {
        printError("modifyBasket()", err)
        dispatch(loadItem("product/goods/shop", id, true, true, false, -1))
        dispatch(clearLoadingIndicator(sourceId))
        dispatch(setDictionary({ oldNetPrice: price }))
        dispatch(setDictionary({ oldProductQuantity: quantity }))

        if (err?.response?.data?.localizationCode === "error.offerInBasket")
          dispatch(
            setPageModal({
              name: "basketErrorOfferShopConflictModal",
              data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
            })
          )
        else if (
          err?.response?.data?.localizationCode === "error.priceChanged" ||
          err?.response?.data?.localizationCode === "error.priceChangedGlobal"
        ) {
          setTimeout(() => {
            dispatch(setDictionary({ priceChanged: true }))
          }, 300)
        } else {
          translatedToastr(err?.response?.data?.localizationCode)
        }
        return {
          type: "a"
        }
      }
    })
  })

  export const addToBasket = (id, quantity, price, sourceId) =>
  reDispatch((dispatch) => {
    dispatch(setLoadingIndicator(sourceId, true))
    return apiAction({
      endPoint: `/basket/add-to-basket`,
      method: "POST",
      data: {
        goodsId: id,
        quantity: quantity,
        price: price?.toFixed(4)
      },
      onSuccess: (data, dispatch) => {
        dispatch(clearLoadingIndicator(sourceId))
        return setBackgroundCartData(data)
      },
      onFailure: (err) => {
        printError("addToBasket()", err)
        dispatch(loadItem("product/goods/shop", id, true, true, false, -1))
        dispatch(clearLoadingIndicator(sourceId))
        dispatch(setDictionary({ oldNetPrice: price }))
        dispatch(setDictionary({ oldProductQuantity: quantity }))

        if (err?.response?.data?.localizationCode === "error.offerInBasket")
          dispatch(
            setPageModal({
              name: "basketErrorOfferShopConflictModal",
              data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
            })
          )
        else if (
          err?.response?.data?.localizationCode === "error.priceChanged" ||
          err?.response?.data?.localizationCode === "error.priceChangedGlobal"
        ) {
          setTimeout(() => {
            dispatch(setDictionary({ priceChanged: true }))
          }, 300)
        } else {
          translatedToastr(err?.response?.data?.localizationCode)
        }
        return {
          type: "a"
        }
      }
    })
  })

export const addArrayToBasket = (data, callType) =>
  reDispatch((dispatch) => {
    return apiAction({
      endPoint: "/basket/add-item-list",
      method: "POST",
      data,
      onSuccess: (data, dispatch) => {
        dispatch(getCart())
        return setBothCartData(data?.basket, data?.lastBasket)
      },
      onFailure: (error) => {
        printError("addArrayToBasket()", error)
        if (error?.response?.data?.localizationCode === "error.offerInBasket")
          dispatch(
            setPageModal({
              name: "basketErrorOfferShopConflictModal",
              data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
            })
          )
        else
          translatedToastr(
            `${error?.response?.data?.localizationCode}${
              callType === "Accessories" ? "Accessories" : ""
            }`
          )
        return {
          type: "a"
        }
      }
    })
  })

export const addOffertToBasket = (data, id) =>
  reDispatch((dispatch) => {
    return apiAction({
      endPoint: "/basket/customer-offer-to-basket",
      method: "POST",
      data: {
        customerOfferId: id,
        itemList: data
      },
      onSuccess: (data, dispatch) => {
        dispatch(getCart())
        setBothCartData(data?.basket, data?.lastBasket)
        dispatch(loadItem("customer-offer", id, true, false))
        return {
          type: "a"
        }
      },
      onFailure: (error) => {
        printError("addOffertToBasket()", error)
        if (
          error?.response?.data?.localizationCode === "error.basketIsNotEmpty"
        )
          dispatch(
            setPageModal({
              name: "basketErrorOfferShopConflictModal",
              data: "În coș se găsesc produs(e) dintr-o altă ofertă sau din magazin. Finalizează comanda, sau golește coșul ca să poți adăuga în coș produse din această ofertă."
            })
          )
        else translatedToastr(error?.response?.data?.localizationCode)
        dispatch(loadItem("customer-offer", id, true, false))
        return {
          type: "a"
        }
      }
    })
  })

export const getCart = (data) =>
  apiAction({
    endPoint: `/basket/my-basket`,
    method: "GET",
    data,
    onSuccess: (data, dispatch) => {

      dispatch(setCampaignApiInfo({
        thisWeekOrderCount: data?.thisWeekOrderCount,
        isCompanyInCampaign: data?.isCompanyInCampaign,
        isUserInCampaign: data?.isUserInCampaign,
      }))

      dispatch(setApiResponse(data))
      dispatch(setCartData(data))
      //dispatch(setCrtFormItem(data, true));
      return {
        type: "a"
      }
    },
    onFailure: (error) => {
      printError("getCart()", error)
      return {
        type: "a"
      }
    }
  })

export const emptyBasket = (sourceId) =>
  reDispatch((dispatch) => {
    dispatch(setLoadingIndicator(sourceId, true))
    return apiAction({
      endPoint: `/basket/my-basket`,
      method: "DELETE",
      // data : {
      //   goodsId: id,
      //   quantity: quantity,
      // },
      onSuccess: (data, dispatch) => {
        // dispatch(setCartData(data?.basket));
        dispatch(clearLoadingIndicator(sourceId))
        return setBothCartData(data?.basket, data?.lastBasket)
      },
      onFailure: (error) => {
        printError("emptyBasket()", error)
        dispatch(clearLoadingIndicator(sourceId))

        return {
          type: "a"
        }
      }
    })
  })

export const placeOrder = (sourceId, cartData) =>

  reDispatch((dispatch) => {

    const finalData = {
      info: cartData?.info,
      localDeliveryDate: cartData?.localDeliveryDate,
      centralDeliveryDate: cartData?.centralDeliveryDate,
      doesAcceptCampaign: cartData?.isAcceptCampaignChecked,
    }

    dispatch(setLoadingIndicator(sourceId, true))
    return apiAction({
      endPoint: `/basket/finalization`,
      method: "POST",
      data: finalData, // data: state?.shop?.CartData,
      onSuccess: (data, dispatch) => {

        try{

          const googlePurchaseEvent = purchaseResponseToGoogleEvent(data)
          TagManager.dataLayer({ dataLayer: googlePurchaseEvent });
          
        } catch (e) {
          printError("placeOrder() - google analytics", e)
        }
        
        
        // dispatch(setApiResponse(data));
        dispatch(setCampaignApiInfo({
          thisWeekOrderCount: data?.thisWeekOrderCount,
          isCompanyInCampaign: data?.isCompanyInCampaign,
          isUserInCampaign: data?.isUserInCampaign,
        }))

        dispatch(clearLoadingIndicator(sourceId))
        if (data?.errorCode === "OVER_LIMIT") {
          dispatch(
            setPageModal(
              "SYS-confirm-action",
              orderManualModalData(data?.id, data?.errorCode, data?.source, data?.code, data?.thisWeekOrderCount, data?.isCompanyInCampaign)
            )
          )
        } else if (data?.errorCode === "NON_PAYED_INVOICE") {
          dispatch(
            setPageModal(
              "SYS-confirm-action",
              orderManualModalData(data?.id, data?.errorCode, data?.source, data?.code,  data?.thisWeekOrderCount, data?.isCompanyInCampaign)
            )
          )
        } else if (data?.errorCode === "SPECIAL_ADDRESS") {
          dispatch(
            setPageModal(
              "SYS-confirm-action",
              orderManualModalData(data?.id, data?.errorCode, data?.source, data?.code,  data?.thisWeekOrderCount, data?.isCompanyInCampaign)
            )
          )
        } else if (data?.errorCode === "PRICE_CHANGED") {
          showMagicToaster({
            title: "Aveţi produse cu preţ sau stoc schimbat în coş.",//"Pretul s-a modificat"
            type: "error"
          })
          dispatch(setExpiredBasket(true))
          //dispatch(getCart())//TODO we might not want this
          return {
            type: "a"
          }
        } else if (data?.errorCode === "NOT_ENOUGH_STOCK") {
          showMagicToaster({
            title:  "Aveţi produse cu preţ sau stoc schimbat în coş.",//
            type: "error"
          })
          dispatch(setExpiredBasket(true))
          return {
            type: "a"
          }
          /*TODO
          another 2 cases no stock error or no price error w toaster
          Aveţi produse fără stoc în coş.
          Aveţi produse fără preţ în coş.
          => dispatch set expired basket true
          return {
            type: "a"
          }
          */
        } else {
          dispatch(
            setPageModal(
              "SYS-confirm-action",//orderAutoModalData 
              orderManualModalData(data?.id,  data?.errorCode, data?.source, data?.code,  data?.thisWeekOrderCount, data?.isCompanyInCampaign)
            )
          )
        }
        return {
          type: CLEAR_CART_DATA
        }
      },
      onFailure: (error) => {
        printError("placeOrder()", error)
        dispatch(clearLoadingIndicator(sourceId))
        if (error?.response?.data?.localizationCode === "error.basketIsEmpty") {
          showMagicToaster({
            title:  "Coșul a fost șters, oferta a expirat",
            type: "error",//raise the time
            timeOut: 5000,
          })
          return {
            type: CLEAR_CART_DATA
          }
        } else {
          toastr.error("A apărut o eroare. Comanda nu a putut fi plasată!")
        }
        return {
          type: "a"
        }
      }
    })
  })
export const refreshBasket = (sourceId) => 
  reDispatch((dispatch) => {
    // let state = store.getState();
    sourceId && dispatch(setLoadingIndicator(sourceId, true))
    return apiAction({
      endPoint: `/basket/refresh`,
      method: "POST",
      // data: state?.shop?.CartData,
      onSuccess: (data, dispatch) => {
        // dispatch(setApiResponse(data));
        //api returns null for images,
        //we need to keep old products url
        sourceId && dispatch(clearLoadingIndicator(sourceId))
        return setBothCartData(data?.basket, data?.lastBasket, true)
        
        //return setCartData({ ...data?.basket, refreshed: moment().format() })//true
      },
      onFailure: (error) => {
        sourceId && dispatch(clearLoadingIndicator(sourceId))
        printError("refreshBasket()", error)
        return {
          type: "a"
        }
      }
    })
})


///work in progress below
export const changeDeliveryAddressInShop = (
  addressId,
  selectedDeliveryAddressName,
  sourceId
) => {
  return apiAction({
    endPoint: `/organization/webshop-client/active-address-change/${addressId}`,
    method: "POST",
    onSuccess: (data, dispatch) => {
      dispatch(
        searchStuff({
          reload: true,
          type: "product/goods/shop",
          sortField: "ranking",
          sortAsc: false
        })
      )
      dispatch(clearLoadingIndicator(sourceId))
      dispatch(getCart())
      dispatch(
        setDictionary({
          selectedDeliveryAddressName: selectedDeliveryAddressName
        })
      )
      return setBothCartData(data?.basket, data?.lastBasket)
    },
    onFailure: (error) => {
      printError("changeDeliveryAddressInShop()", error)
      return {
        type: "a"
      }
    }
  })
}

export const changeDeliveryAddressInCart = (
  addressId,
  selectedDeliveryAddressName,
  sourceId
) => {
  return apiAction({
    endPoint: `/basket/address-change/${addressId}`,
    method: "POST",
    onSuccess: (data, dispatch) => {
      dispatch(
        searchStuff({
          reload: true,
          type: "product/goods/shop",
          sortField: "ranking",
          sortAsc: false
        })
      )
      dispatch(clearLoadingIndicator(sourceId))
      dispatch(getCart())
      dispatch(
        setDictionary({
          selectedDeliveryAddressName: selectedDeliveryAddressName
        })
      )
      return setBothCartData(data?.basket, data?.lastBasket)
    },
    onFailure: (error) => {
      printError("changeDeliveryAddressInCart()", error)
      return {
        type: "a"
      }
    }
  })
}

export const changePaymentType = (paymentType) => {
  // let state = store.getState();
  return apiAction({
    endPoint: `/basket/payment-method-change/${paymentType}`,
    method: "POST",
    // data: state?.shop?.CartData,
    onSuccess: (data, dispatch) => {
      // dispatch(setApiResponse(data));

      return setBothCartData(data?.basket, data?.lastBasket)
    },
    onFailure: (error) => {
      printError("changePaymentType()", error)
      return {
        type: "a"
      }
    }
  })
}
const updateLineList = ( lineList, modifiedProduct) => {
    const oldProductIndex = [...lineList || []].findIndex?.(oldProduct => oldProduct?.productId === modifiedProduct?.addProductId);
    if(modifiedProduct?.addProductQuantity === 0 && oldProductIndex >= 0) {
      [...lineList || []]?.filter?.(i => i.productId !== modifiedProduct?.addProductId)
      return lineList
    }
    if(oldProductIndex >= 0) {
      lineList[oldProductIndex] = {...lineList?.[oldProductIndex], quantity: modifiedProduct?.addProductQuantity} 
      return lineList
    }
    lineList = [...lineList || [], {productId: modifiedProduct?.addProductId, quantity: modifiedProduct?.addProductQuantity}]
    return lineList
}
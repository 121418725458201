
import { useSelector } from "store"

export const useShouldShowDepotMovedDisclaimer = () => {

  /**
   * NOT the case anymore.
   * Had to be shown when the address was in Iasi.
   */

  // const addressList = useSelector((state) => state.page?.crtFormItem?.addressList)
  // const addressListProcessed = useSelector((state) => state.page?.crtFormItem?.addressListProcessed)
  
  // const onBehalfAddressList = useSelector((state) => state.page?.onBehalfAddressList)

  // const allAddresses = [
  //   ...addressList || [], 
  //   ...onBehalfAddressList || [],
  //   ...addressListProcessed || [],
  // ]

  // const shouldShowMovedDisclaimer = Boolean(
  //   allAddresses?.some?.((address) => {
  //     const lowercaseAddressName = address?.name?.toLowerCase?.()
  //     const lowercaseLocationName = address?.locationName?.toLowerCase?.()
  //     const lowercaseCountyName = address?.countyName?.toLowerCase?.()
  //     return (
  //       lowercaseAddressName?.includes?.('iasi') || 
  //       lowercaseAddressName?.includes?.('iași') || 
  //       lowercaseLocationName?.includes?.('iasi') ||
  //       lowercaseLocationName?.includes?.('iași') || 
  //       lowercaseCountyName?.includes?.('iasi') ||
  //       lowercaseCountyName?.includes?.('iași')
  //     )
  //   })
  // )
  
  return false
}

export const purchaseResponseToGoogleEvent = (input) => {
  return {
    event: "purchase",
    ecommerce: {
      currency: input.currencyCode,
      transaction_id: input.code,
      value: input.totalValueGross.toFixed(2),
      shipping: '0.00', // Assuming no shipping cost is provided
      tax: input.totalValueVat.toFixed(2),
      items: input.lineList.map(product => ({
        item_id: product.productId,
        item_name: product.productName,
        price: product.unitPriceGross.toFixed(2),
        item_brand: product.productBrandName,
        quantity: product.quantity,
      })),
    }
  };
}


// const exampleInput = {
//   id: "roPqvZ",
//   status: "BaseEntityStatus.PROCESSED",
//   paidInvoicesCount: 0,
//   allInvoicesCount: 0,
//   code: "B_019049",
//   thisWeekOrderCount: 0,
//   isCompanyInCampaign: null,
//   isUserInCampaign: null,
//   isOrderInCampaign: false,
//   currencyCode: "RON",
//   totalValueNet: 1768.71,
//   totalValueVat: 336.06,
//   totalValueGross: 2104.77,
//   totalValuePaid: 0,
//   deliveryAddressId: "grKzem",
//   deliveryAddressName: "SediuSoc",
//   deliveryAddress: "str ioan Voda cel Viteaz nr 1 parter camera 4 scara A ap1",
//   clientInvoiceAddressId: null,
//   clientInvoiceAddressName: null,
//   clientInvoiceAddress: null,
//   clientId: "3ZVaxr",
//   clientName: "VASCO TEHNIC SOLUTIONS SRL",
//   clientFiscalNr: "RO33236831",
//   clientVatNr: "J22 /920 /2014",
//   customerOfferId: null,
//   customerOfferCode: null,
//   customerOfferExpirationDate: null,
//   paymentMethodType: "CREDIT_LIMIT",
//   deliveryCount: 1,
//   errorCode: "NON_PAYED_INVOICE",
//   info: null,
//   productCount: 3,
//   deliveryList: [{
//     id: "rweDVl",
//     workplaceId: "3r2dlO",
//     workplaceName: "DEPOZIT CENTRAL TÂRGU MUREȘ",
//     warehouseId: "Xl0Jr1",
//     warehouseName: "Depozit central",
//     orderId: "mG5BW6",
//     orderCode: "O_020269/1",
//     orderExternalCode: null,
//     orderStatus: "NEW",
//     orderDeliveryDate: "2024-12-12T14:19:09.966+00:00",
//     deliveryMethodSettings: {
//       internal: true,
//       onsite: true,
//       external: false
//     },
//     lineList: [{
//       productId: "leNB1m",
//       productName: "GEBERIT REZERVOR WC STOP&GO AP110",
//       warehouseId: "Xl0Jr1",
//       warehouseName: "Depozit central",
//       quantity: 1.00,
//       valueNet: 120.9000,
//       valueVat: 22.9710,
//       valueGross: 143.8710
//     }, {
//       productId: "Z4zpJm",
//       productName: "REHAU RAUTHERM S TEAVA PE-XA 17X2 - 500M",
//       warehouseId: "Xl0Jr1",
//       warehouseName: "Depozit central",
//       quantity: 500.00,
//       valueNet: 1616.6500,
//       valueVat: 307.1635,
//       valueGross: 1923.8135
//     }, {
//       productId: "l722vZ",
//       productName: "RBM ROBINET RADIATOR COLTAR 1/2\"",
//       warehouseId: "Xl0Jr1",
//       warehouseName: "Depozit central",
//       quantity: 2.00,
//       valueNet: 31.1628,
//       valueVat: 5.9209,
//       valueGross: 37.0837
//     }],
//     costNet: 0.00,
//     costVat: 0.00,
//     costGross: 0.00,
//     currencyCode: "RON",
//     deliveryMethodType: "ONSITE",
//     deliveryTimeType: "H12_24",
//     priority: 1,
//     invoiceList: []
//   }],
//   lineList: [{
//     productId: "leNB1m",
//     productCode: "GGE11600886",
//     productMainImage: "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/AP110.jpg",
//     productBrandName: "GEBERIT",
//     productName: "GEBERIT REZERVOR WC STOP&GO AP110",
//     quantity: 1.00,
//     unitOfMeasure: "buc",
//     unitPriceVat: 22.9710,
//     unitPriceNet: 120.9000,
//     unitPriceGross: 143.8710,
//     valueVat: 22.9710,
//     valueNet: 120.9000,
//     valueGross: 143.8710,
//     discount: null,
//     discountVolumeId: null,
//     discountVolume: null,
//     currencyCode: "RON",
//     info: null,
//     localStockQuantity: 2845.0000,
//     centralStockQuantity: 0,
//     sellQuantityMinim: 1.0,
//     sellQuantityIncrement: 1.0,
//     errorCode: null,
//     idx: 10
//   }, {
//     productId: "Z4zpJm",
//     productCode: "F0050145X25X36",
//     productMainImage: "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/Teava-Pex-Rehau-Rautherm-S.jpg",
//     productBrandName: "REHAU",
//     productName: "REHAU RAUTHERM S TEAVA PE-XA 17X2 - 500M",
//     quantity: 500.00,
//     unitOfMeasure: "m",
//     unitPriceVat: 0.6143,
//     unitPriceNet: 3.2333,
//     unitPriceGross: 3.8476,
//     valueVat: 307.1635,
//     valueNet: 1616.6500,
//     valueGross: 1923.8135,
//     discount: null,
//     discountVolumeId: null,
//     discountVolume: null,
//     currencyCode: "RON",
//     info: null,
//     localStockQuantity: 28130.0000,
//     centralStockQuantity: 0,
//     sellQuantityMinim: 1.0,
//     sellQuantityIncrement: 500.0,
//     errorCode: null,
//     idx: 20
//   }, {
//     productId: "l722vZ",
//     productCode: "Z0049017X12XXXX",
//     productMainImage: "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/1510300.jpg",
//     productBrandName: "RBM",
//     productName: "RBM ROBINET RADIATOR COLTAR 1/2\"",
//     quantity: 2.00,
//     unitOfMeasure: "buc",
//     unitPriceVat: 2.9605,
//     unitPriceNet: 15.5814,
//     unitPriceGross: 18.5419,
//     valueVat: 5.9209,
//     valueNet: 31.1628,
//     valueGross: 37.0837,
//     discount: null,
//     discountVolumeId: null,
//     discountVolume: null,
//     currencyCode: "RON",
//     info: null,
//     localStockQuantity: 5655.0000,
//     centralStockQuantity: 0,
//     sellQuantityMinim: 1.0,
//     sellQuantityIncrement: 1.0,
//     errorCode: null,
//     idx: 30
//   }],
//   source: "INTERNAL"
// }

// const exampleOutput = {
//   event: "purchase",
//   ecommerce: {
//     currency: "EUR",
//     transaction_id: "[transaction id]", // online transaction ID from webshop backend
//     value: "[transaction value]", // value of the transaction
//     shipping: "[shipping cost]", // if available total amount of shipping costs
//     tax: "[taxes]", // total amount of taxes on the transactions
//     items: [
//       {
//         item_id: "[item ID]", // id (internal ID) of item which is part of the transaction
//         item_name: "[item name]", // name of product in lowercase
//         price: "[item price]", // price of item
//         item_brand: "[item brand]", // brand of the item
//         quantity: [quantity] // quantity if the item
//       },
//       {
//         item_id: "[product/item ID]", // id (internal ID) of item which is part of the t ransaction
//         item_name: "[product/item name]", // name of product in lowercase
//         price: "[item price]", // price of item
//         item_brand: "[item brand]", // brand of the item
//         quantity: "[quantity]" // quantity if the item
//       }
//     ]
//   }
// }
